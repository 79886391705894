import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { getWinners } from "api/promo";
import { useIntersection, useReduxDispatch } from "hooks";
import { ApiAnswerStatus, PromoUserGalleryItem, PromoUserWinner } from "types";
import { propsAction } from "store/props";
import { mediaViewerModalName } from "../modals";
import { useStyles } from "../promo.style";
import { WinnerList } from "./winner-list";
import { Search } from "@material-ui/icons";
import { numericInputProps, PhoneMask } from "../../../components";
import { useHistory } from "react-router-dom";
import {scroller} from "react-scroll";

const WinnersSection: React.FC = () => {
  const classes = useStyles();
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));

  const dispatch = useReduxDispatch();

  const [inView, setInView] = useState(false);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [winners, setWinners] = useState<PromoUserWinner[]>([]);
  const history = useHistory();
  const winnersRef = useRef<HTMLDivElement>(null);

  useIntersection(winnersRef, () => {
    if (!inView) setInView(true);
  });


  const fetchWinners = async () => {
    setLoading(true);
    const { data, status } = await getWinners({
      phone: phone
        ? phone.replace(/[^0-9]/g, "").replace(/^7/g, "")
        : undefined,
    });
    setLoading(false);

    if (status === ApiAnswerStatus.SUCCESS && typeof data === typeof winners) {
      setWinners(data);
    }
  };

  useEffect(() => {
    // if (inView)
      fetchWinners();
  }, []);

  const showGalleryItemModal = (params?: PromoUserGalleryItem) => {
    dispatch(
      propsAction.modal({
        modal: {
          [mediaViewerModalName]: {
            show: true,
            params: { ...params, hideVote: true },
          },
        },
      })
    );
  };

  useEffect(() => {
    if (!loading && history.location.hash === "#winner") {
      setTimeout(() => {
        scroller.scrollTo("winner", {
          ignoreCancelEvents: true,
          activeClass: "active",
          duration: 700,
          hashSpy: true,
          smooth: true,
          offset: 0,
          spy: true,
        });
        history.push({ hash: "" });
      }, 1000);
    }

  },[loading])

  return (
    <Box pt={{ xs: 5, sm: 8 }} pb={2} style={{ background: "#D6E4F3" }} id={"winner"}>
      <div ref={winnersRef}>
        <Container maxWidth="xl">
          <Box position="relative">
            {/*<Box fontSize={{ xs: 24, md: 45 }}>*/}
            {/*  <Typography*/}
            {/*    variant="h2"*/}
            {/*    style={{*/}
            {/*      marginTop: 0,*/}
            {/*      fontSize: "inherit",*/}
            {/*      textTransform: "uppercase",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Победители*/}
            {/*  </Typography>*/}
            {/*</Box>*/}

            {!loading && winners.length === 0 && !isSearch && (
              <Box textAlign="center">
                <Typography variant="body2">Ждем первых победителей</Typography>
              </Box>
            )}

            {!loading && (winners.length > 0 || isSearch) && (
              <Grid container spacing={sm ? 2 : 4} >
                <Grid item xs={12}>
                  <Box className={classes.winnersBoxTitle}>
                    <div className={"winnerTitle"}>
                      <Box fontSize={{ xs: 24, md: 45 }} >
                        <Typography
                          variant="h2"
                          style={{
                            marginTop: 0,
                            fontSize: "inherit",
                            textTransform: "uppercase",
                            paddingBottom: 0,
                            marginBottom: 0,
                          }}
                        >
                          Победители
                        </Typography>
                      </Box>
                    </div>
                  </Box>
                  <Box className={classes.winnersBox}>
                    <Box className={classes.winnersBoxSearch}>
                      <FormControl
                        variant="filled"
                        style={{
                          width: "50%",
                          minWidth: "300px",
                          borderWidth: "2px",
                        }}
                      >
                        <TextField
                          type={"text"}
                          value={phone}
                          variant={"filled"}
                          InputProps={{
                            inputComponent: PhoneMask as any,
                            inputProps: { ...(numericInputProps as any) },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  style={{ fontSize: "30px" }}
                                  variant="contained"
                                  color="secondary"
                                  // aria-label="toggle password visibility"
                                  // edge="end"

                                  onClick={() => {
                                    setIsSearch(true);
                                    fetchWinners();
                                  }}
                                >
                                  <Search />
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <WinnerList items={winners} />
                  </Box>
                </Grid>
              </Grid>
            )}

            <Fade in={loading}>
              <Box
                position="absolute"
                textAlign="center"
                bgcolor="rgba(255,255,255,0.5)"
                left={0}
                top={0}
                bottom={0}
                right={0}
              >
                <Box mt={6}>
                  <CircularProgress />
                </Box>
              </Box>
            </Fade>
          </Box>
        </Container>
      </div>
    </Box>
  );
};

export default WinnersSection;
